.locIcon {
    margin-right: 10px;
    color: white;
  }
  
  .thermometer,
  .tint,
  .wind {
    font-size: 3rem;
  }
  
  .button-toggle {
    border-style: none;
    font-size: 15px;
    border-radius: 1rem;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
    padding: 10px;
    margin-top: 50px;
  }
  
  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.247);
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
  }
  
  .item1 {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  
  .item2 {
    grid-row-start: 2;
    grid-row-end: 4;
    display: grid;
    grid-template-columns: auto;
  }
  
  .item3 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  
  .item21 {
    font-size: 10rem;
  }
  
  @media (max-width: 600px) {
    .grid-container {
      display: flex;
      flex-direction: column;
      align-items: center; 
    }
  
    .grid-container > div {
      margin-top: 10px;
      font-size: 15px; 
      width: 10rem;
    }
  
    .item21 {
      font-size: 4rem; 
    }
  }
  